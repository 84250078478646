exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-casting-couch-hd-js": () => import("./../../../src/pages/casting-couch-hd.js" /* webpackChunkName: "component---src-pages-casting-couch-hd-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-net-girl-js": () => import("./../../../src/pages/net-girl.js" /* webpackChunkName: "component---src-pages-net-girl-js" */),
  "component---src-pages-net-video-girls-js": () => import("./../../../src/pages/net-video-girls.js" /* webpackChunkName: "component---src-pages-net-video-girls-js" */),
  "component---src-pages-nvgcom-js": () => import("./../../../src/pages/nvgcom.js" /* webpackChunkName: "component---src-pages-nvgcom-js" */),
  "component---src-templates-videopage-js": () => import("./../../../src/templates/videopage.js" /* webpackChunkName: "component---src-templates-videopage-js" */)
}

